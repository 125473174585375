<template>
  <el-container>
    <div class="w-100">
      <div class="content">
        <el-card class="box-card">
          <el-tabs v-model="activeName" @tab-click="handleClick">
            <el-tab-pane label="军事动态" name="dynamic">
              <mility-dynamic v-if="activeName === 'dynamic'" />
            </el-tab-pane>
            <el-tab-pane label="军事信息列表" name="list">
              <mility-list v-if="activeName === 'list'" />
            </el-tab-pane>
          </el-tabs>
        </el-card>
      </div>
    </div>
  </el-container>
</template>
<script type="application/javascript">
  import Dynamic from "./tabs/dynamic.vue"
  import List from "./tabs/list.vue"
  export default {
    name: "mility",
    components: {
      "mility-dynamic": Dynamic,
      "mility-list": List
    },
    data() {
      return {
        activeName: 'dynamic'
      }
    },
    methods:{
      handleClick(tab, event) {
      }
    }
  }
</script>
<style scoped>
</style>
