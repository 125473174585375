<template>
  <div class="feature">
    <el-dropdown v-show="showDown">
      <i class="iconfont icon-xiazai"></i>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item>
          <div @click="downPhoto">
            <i class="iconfont icon-png"></i>导出PNG
          </div>
        </el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>

    <el-dropdown class="select_box" v-for="(item, index) in filter" :key="index">
      <h2>{{ item.value.label }}</h2>
      <el-dropdown-menu slot="dropdown">
        <slot name="filter">
          <div v-for="(option, index) in item.options" :key="index" @click="changeFilter(item, option, item.field)">
            <el-dropdown-item>
              <span>{{ option.label }}</span>
            </el-dropdown-item>
          </div>
        </slot>
      </el-dropdown-menu>
    </el-dropdown>
  </div>
</template>
<script>
  export default {
    props: {
      showDown: {
        type: Boolean,
        default: false
      },
      filter: {
        type: Array,
        required: true
      }
    },
    data() {
      return {
      };
    },
    mounted() {
    },
    methods: {
      changeFilter(item, option, field) {
        if (option.label !== item.value.label) {
          // 触发筛选条件
          item.value = option
          this.$emit('filter', field, option)
        }
      },
      downPhoto() {
        this.$emit('downPhoto')
      }
    }
  };
</script>
<style scoped>
  .feature {position: absolute;right:0;top:0;z-index:1998;height: 25px;line-height: 25px;}
  .feature .el-dropdown{float: right;margin-left: 10px;}
  .select_box h2{font-size: 12px;margin:0;padding-right: 20px;float: left;line-height: 25px;position: relative;}
  .select_box h2::after{width: 0px;height: 0px;border-left: 5px solid transparent;border-top: 5px solid #b5b5b5;border-right: 5px solid transparent;position: absolute;top:10px;right:0;z-index: 1000;content: "";}
</style>